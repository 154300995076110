<template>
  <v-col cols="12">
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card>
      <v-card-title>Régions Web</v-card-title>

      <v-card-text>
        <div class="global-actions">
          <router-link :to="{ name: 'regionWebCreate' }">
            <v-btn
              small
              color="primary"
              class="mt-5"
            >
              Ajouter une région web
            </v-btn>
          </router-link>
        </div>
      </v-card-text>

      <div class="clear"></div>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-uppercase">
                Nom
              </th>
              <th class="text-center text-uppercase">
                Etat
              </th>
              <th class="text-center text-uppercase">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(regionWeb, regionWebIndex) in regionsWeb"
              :key="'e' + regionWebIndex"
            >
              <td class="font-weight-bold">
                {{ resolveAttrValue(regionWeb, 'name') }}
              </td>
              <td class="text-center">
                <v-chip
                  v-if="regionWeb.enabled"
                  color="success"
                  text-color="white"
                  small
                >
                  Activé
                </v-chip>
                <v-chip
                  v-if="!regionWeb.enabled"
                  color="error"
                  text-color="white"
                  small
                >
                  Désactivé
                </v-chip>
              </td>
              <td class="text-center">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="warning"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      :to="{ name: 'regionWebDetails', params: { regionWebId: regionWeb.id }}"
                    >
                      <v-icon dark>
                        {{ icons.mdiEye }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Consulter</span>
                </v-tooltip>

                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      class="ml-2"
                      color="error"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      @click="openDeleteRegionWebDialog(regionWeb)"
                    >
                      <v-icon dark>
                        {{ icons.mdiTrashCan }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Supprimer</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!regionsWeb.length">
            <tr>
              <td>
                <span v-if="loading === false">Aucun éléments.</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <DeleteRegionWebDialog
        :key="Date.now()"
        :delete-region-web-dialog-state="deleteRegionWebDialogState"
        :region-web="regionWebToDelete"
        @closeDeleteRegionWebDialog="closeDeleteRegionWebDialog($event)"
      ></DeleteRegionWebDialog>
    </v-card>
  </v-col>
</template>

<script>
import { mdiPlay, mdiPen, mdiEye, mdiTrashCan } from '@mdi/js'
import Vue from 'vue'
import DeleteRegionWebDialog from '@/components/Dialog/LocationDialog/RegionWeb/DeleteRegionWebDialog'
import config from '../../../config'
import Loader from '@/components/Common/Loader'

export default {
  components: {
    DeleteRegionWebDialog,
    Loader
  },
  data: () => ({
    regionsWeb: [],
    regionWebToDelete: null,
    deleteRegionWebDialogState: false,
    loading: false,
    icons: {
      mdiPlay,
      mdiPen,
      mdiEye,
      mdiTrashCan
    }
  }),
  created () {
    this.getRegionWebs()
  },
  methods: {
    getRegionWebs () {
      this.loading = true

      const params = {
        limit: 150
      }

      Vue.prototype.$http
        .get(config.apiUrl + '/api/location/regions-web', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              this.regionsWeb = data
            }
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    openDeleteRegionWebDialog (regionWeb) {
      this.deleteRegionWebDialogState = true
      this.regionWebToDelete = regionWeb
    },
    closeDeleteRegionWebDialog (deleteState) {
      if (deleteState === true) {
        this.regionsWeb = this.regionsWeb.filter(element => element.id !== this.regionWebToDelete.id)
      }

      this.deleteRegionWebDialogState = false
    },
    resolveAttrValue (data, attr) {
      const translations = data.translations

      if (translations.fr) {
        return translations.fr[attr]
      }

      return '-'
    }
  }
}
</script>

